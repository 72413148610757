import { Button, TextField, useTheme } from '@mui/material'
import Navbar from '../../utils/Navbar/Navbar'
import './joinMatch.scss'
import { useState } from 'react'
import myFetch from '../../utils/myFetch'
import { useNavigate } from 'react-router-dom'

const JoinMatch = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [code, setCode] = useState()

    const joinMatch = async () => {
        await myFetch('/api/joinMatch', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: code, userID: localStorage.getItem('loggedUserID') }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.status == 0) {
                    console.log(res.match.id)
                    navigate('/lobby/' + res.match.id)
                }
            })
    }

    return (
        <>
            <Navbar />
            <div className='joinMatchMain'>
                <header>
                    <p>Podaj kod pojedynku</p>
                </header>
                <section>
                    <TextField
                        onChange={e => {
                            setCode(e.target.value)
                        }}
                        type='number'
                        variant='standard'
                        label='6-cyfrowy kod'
                    />
                    <Button onClick={joinMatch} variant='contained'>
                        Dołącz
                    </Button>
                </section>
            </div>
        </>
    )
}

export default JoinMatch
