import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'
import './browseSet.scss'
import VerifiedIcon from '@mui/icons-material/Verified'
import { Tooltip } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Navbar from '../../utils/Navbar/Navbar'

const BrowseSet = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    let { setID } = useParams()
    const [setName, setSetName] = useState('')
    const [setAuthor, setSetAuthor] = useState('')
    const [listFiszki, setListFiszki] = useState()
    const [arrayFiszki, setArrayFiszki] = useState([{ side1: '', side2: '' }])
    const [setIcon, setSetIcon] = useState()
    const [category, setCategory] = useState('Inne')
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const [isByAdmins, setIsByAdmins] = useState(false)
    const [acutalPosition, setActualPosition] = useState(0)

    const [fiszkaStatus, setFiszkaStatus] = useState(false)
    const [animationValue, setAnimationValue] = useState('none')
    const animationStarter = 'obrotFiszki 0.5s linear'
    const nextFiszkaStarter = 'nastepnaFiszka 0.5s linear'
    const backwardsFiszkaStarter = 'nastepnaFiszka 0.5s linear reverse'

    const przycisk = e => {
        console.log(e.keyCode)
        if (e.keyCode == 37) {
            goBack()
        } else if (e.keyCode == 39) {
            goForward()
        } else if (e.keyCode == 32) {
            changeFiszkaStatus()
        }
    }

    useEffect(() => {
        console.log('aaa')
        window.addEventListener('keydown', przycisk)
        return () => {
            window.removeEventListener('keydown', przycisk)
        }
    }, [przycisk])

    useEffect(() => {
        getSetData()
    }, [])

    const getSetData = async () => {
        await myFetch('/api/getSetData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setSetName(res.name)
                setSetAuthor(res.author.nick)
                setArrayFiszki(res.fiszki)
                setIsByAdmins(res.isByAdmins)
                if (res.category == 1) {
                    setSetIcon(require('../../assets/images/english.webp'))
                    setCategory('Angielski')
                } else if (res.category == 2) {
                    setSetIcon(require('../../assets/images/german.webp'))
                    setCategory('Niemiecki')
                } else {
                    setSetIcon(require('../../assets/images/fishki_nobg.webp'))
                    setCategory('Inne')
                }
            })
    }

    const goBack = () => {
        if (acutalPosition > 0) {
            setAnimationValue(backwardsFiszkaStarter)
            setTimeout(() => {
                setActualPosition(acutalPosition - 1)
            }, 250)
            setTimeout(() => {
                setAnimationValue('none')
            }, 500)
        }
    }

    const goForward = () => {
        if (acutalPosition < arrayFiszki.length - 1) {
            setAnimationValue(nextFiszkaStarter)
            setTimeout(() => {
                setActualPosition(acutalPosition + 1)
            }, 250)
            setTimeout(() => {
                setAnimationValue('none')
            }, 500)
        }
    }

    const changeFiszkaStatus = async () => {
        setAnimationValue(animationStarter)
        setTimeout(() => {
            setFiszkaStatus(!fiszkaStatus)
        }, 250)
        setTimeout(() => {
            setAnimationValue('none')
        }, 500)
    }

    return (
        <>
            <Navbar />
            <div className='mainBrowseSet'>
                <img className='background-image' src={setIcon} />
                <header>
                    {setName}{' '}
                    {isByAdmins ? (
                        <Tooltip title='Zestaw tworzony przed administratorów'>
                            <VerifiedIcon fontSize='large' color='secondary' />
                        </Tooltip>
                    ) : null}
                </header>
                <article>
                    <aside>
                        <div onClick={goBack}>
                            <ArrowBackIcon style={{ opacity: acutalPosition == 0 ? 0.4 : 1 }} fontSize='large' />
                        </div>
                    </aside>
                    <section>
                        <article
                            onClick={changeFiszkaStatus}
                            style={{
                                backgroundColor: fiszkaStatus ? theme.palette.secondary.main : theme.palette.primary.main,
                                animation: animationValue,
                            }}>
                            <header>{fiszkaStatus ? '2 strona' : '1 strona'}</header>
                            <div>{fiszkaStatus ? arrayFiszki[acutalPosition].side2 : arrayFiszki[acutalPosition].side1}</div>
                        </article>
                        <footer>{acutalPosition + 1 + ' / ' + arrayFiszki.length}</footer>
                    </section>
                    <aside>
                        <div onClick={goForward}>
                            <ArrowForwardIcon style={{ opacity: acutalPosition == arrayFiszki.length - 1 ? 0.4 : 1 }} fontSize='large' />
                        </div>
                    </aside>
                </article>
            </div>
        </>
    )
}

export default BrowseSet
