import { Button, useTheme } from '@mui/material'
import Navbar from '../../utils/Navbar/Navbar'
import './download.scss'
import myFetch from '../../utils/myFetch'

const Download = () => {
    const theme = useTheme()

    const downloadExe = async () => {
        await myFetch('/api/downloadExe')
            .then(res => res.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = 'fishki.exe'
                a.click()
            })
    }

    return (
        <>
            <Navbar />
            <div className='downloadMain'>
                <header>Pobierz aplikację fiszki</header>
                <section className='gora'>
                    <p>Testowa wersja aplikacji Fishki jest dostępna do pobrania za darmo na komputery z systemem Windows</p>
                    <p>W przyszłości planujemy dodać też wersję na systemy macOS</p>
                    <p>Nie wykluczamy też utworzenia okrojonej wersji aplikacji na urządzenia mobilne</p>
                </section>
                <img src={require('../../assets/images/fishki_nobg_sm.png')} />
                <section className='dol'>
                    <p>Wersja aplikacji: 0.7</p>
                    <p>Rozmiar aplikacji: ~8MB</p>
                    <div>
                        <Button disabled variant='contained'>
                            Pobierz (instalator)
                        </Button>
                        <Button onClick={downloadExe} variant='contained'>
                            Pobierz (przenośne)
                        </Button>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Download
