import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'

const makeSetCopy = async (setID, userID, alertSetter, navigate) => {
    await myFetch('/api/makeSetCopy', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ setID: setID, userid: userID }),
    })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if (res.status == 0) {
                navigate('/editor/' + res.result.id)
                navigate(0)
            } else {
                alertSetter(
                    myAlert(
                        'Wystąpił błąd',
                        res.error,
                        'Zamknij',
                        () => {
                            alertSetter(null)
                        },
                        'error'
                    )
                )
            }
        })
}

export default makeSetCopy
