import { Button } from '@mui/material'
import { useRef, useState } from 'react'
import myAlert from '../../utils/AlertProvider'

const JSONReader = ({ setArrayFiszki, setAlertPlaceholder }) => {
    const inputRef = useRef()
    const buttonClicked = () => {
        inputRef.current.click()
    }
    const fileChanged = event => {
        const file = event.target.files[0] // Get the selected file

        if (file) {
            const reader = new FileReader()

            // Event handler for when the file is successfully read
            reader.onload = e => {
                try {
                    const json = JSON.parse(e.target.result) // Parse the JSON data
                    json.forEach(element => {
                        if (element.side1 == null || element.side2 == null) {
                            throw new Error('Invalid json')
                        }
                    })
                    setArrayFiszki(json)
                } catch (err) {
                    setAlertPlaceholder(
                        myAlert(
                            'Wystąpił błąd',
                            'Plik JSON jest nieprawidłowy. Zobacz pomoc aby dowiedzieć się więcej',
                            'Zamknij',
                            () => {
                                setAlertPlaceholder(null)
                            },
                            'error'
                        )
                    )
                }
            }

            // Read the file as text
            const result = reader.readAsText(file)
        }
    }
    return (
        <>
            <Button onClick={buttonClicked} color='secondary' variant='outlined'>
                Wczytaj z pliku JSON
            </Button>
            <input onChange={fileChanged} ref={inputRef} type='file' style={{ display: 'none' }} />
        </>
    )
}

export default JSONReader
