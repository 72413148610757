import './lobby.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../utils/Navbar/Navbar'
import { Backdrop, Button, CircularProgress, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'

const Lobby = () => {
    let { matchID } = useParams()
    const navigate = useNavigate()
    const theme = useTheme()

    const [matchCode, setMatchCode] = useState('000000')
    const [isLoading, setIsLoading] = useState(true)
    const [matchStatus, setMatchStatus] = useState(0)
    const [matchData, setMatchData] = useState(null)
    const [setData, setSetData] = useState(null)
    const [hostData, setHostData] = useState(null)
    const [hostReady, setHostReady] = useState(false)
    const [guestData, setGuestData] = useState(null)
    const [setIcon, setSetIcon] = useState(require('../../assets/images/fishki_nobg_sm.png'))
    const [category, setCategory] = useState('Inne')
    const link = window.location.hostname === 'localhost' ? 'https://localhost:3001/' : 'https:/fishki.rurbanski.pl/'

    useEffect(() => {
        myFetch('/api/getFullMatchData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ matchID: matchID }),
        })
            .then(res => res.json())
            .then(res => {
                setGuestData(res.guest)
                setHostData(res.host)
                setMatchData(res)
                setSetData(res.set)
                setMatchCode(res.code)
                if (res.set.category == 1) {
                    setSetIcon(require('../../assets/images/english.png'))
                    setCategory('Angielski')
                } else if (res.set.category == 2) {
                    setSetIcon(require('../../assets/images/german.png'))
                    setCategory('Niemiecki')
                } else {
                    setSetIcon(require('../../assets/images/fishki_nobg_sm.png'))
                    setCategory('Inne')
                }
                setIsLoading(false)
                const interwal = setInterval(async () => {
                    try {
                        await myFetch('/api/requestMatchStatus', {
                            method: 'post',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ matchID: res.matchID }),
                        })
                            .then(res => res.json())
                            .then(async res => {
                                console.log(res.match.matchStatus)
                                setMatchStatus(res.match.matchStatus)
                                if (!guestData && res.match.hostID != res.match.guestID) {
                                    await myFetch('/api/getBasicUserData', {
                                        method: 'post',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({ userid: res.match.guestID }),
                                    })
                                        .then(res => res.json())
                                        .then(res => {
                                            setGuestData(res)
                                        })
                                }
                                if (res.match.matchStatus == 3) {
                                    navigate('/match/' + res.match.id + '/' + res.match.playedSet.id)
                                }
                            })
                    } catch (err) {
                        console.log(err)
                    }
                }, 500)
                return () => {
                    clearInterval(interwal)
                }
            })
    }, [])

    const getReady = async () => {
        setHostReady(true)
        await myFetch('/api/setMatchReady', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ matchID: matchData.matchID, userID: localStorage.getItem('loggedUserID') }),
        })
    }

    return (
        <>
            <Navbar />
            <div className='createMatchMain'>
                <header>Nowy pojedynek - {setData ? setData.name : ''}</header>

                <div>
                    <aside>
                        <img src={setIcon} />
                        <p className='matchCode'>
                            Kod meczu: <span style={{ color: theme.palette.primary.light }}>{matchCode}</span>
                        </p>
                        <p>Kategoria: {category}</p>
                        <p>Długość: {setData ? setData.fiszki.length : 0}</p>
                    </aside>
                    <section>
                        {hostData ? (
                            <>
                                <img src={link + hostData.photoPath} />
                                <p>{hostData.nick}</p>
                                <div style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Oczekiwanie na przeciwnika...</div>
                            </>
                        ) : (
                            <CircularProgress color='primary' />
                        )}
                    </section>
                    <p style={{ color: theme.palette.primary.light }}>VS</p>
                    <section>
                        {matchStatus == 1 ? (
                            <>
                                <CircularProgress style={{ scale: 2 }} color='primary' />
                                <p>Oczekiwanie na przeciwnika...</p>
                            </>
                        ) : guestData ? (
                            <>
                                <img src={link + guestData.photoPath} />
                                <p>{guestData.nick}</p>
                                {matchStatus == 2 ? (
                                    <Button disabled={hostReady} onClick={getReady} variant='contained'>
                                        {hostReady ? 'Gotowy' : 'Gotowość'}
                                    </Button>
                                ) : (
                                    <>Oczekiwanie na przeciwnika...</>
                                )}
                            </>
                        ) : null}
                    </section>
                </div>
            </div>
            <Backdrop open={isLoading}>
                <CircularProgress color='primary' />
            </Backdrop>
        </>
    )
}

export default Lobby
