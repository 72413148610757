import { Button } from '@mui/material'

const HowToJSON = ({ setShowHelp }) => {
    return (
        <section className='helpCard'>
            <header>Jak wczytywać pliki JSON?</header>
            <p>Edytor pozwala na wczytywanie zestawów fiszek z plików JSON</p>
            <p>Pliki te muszą mieć odpowiednią strukturę, przykład poniżej: </p>
            <img style={{ width: 500, marginTop: 15, marginBottom: 15 }} src={require('../../assets/images/jsonExample.png')} />
            <br></br>
            <Button
                variant='contained'
                onClick={() => {
                    setShowHelp(false)
                }}>
                Zamknij
            </Button>
        </section>
    )
}

export default HowToJSON
