import { Tooltip, useTheme } from '@mui/material'
import './newSetsSection.scss'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'
import { useNavigate } from 'react-router-dom'

const RecomendedSection = ({ setAlertPlaceholder }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const [setsArray, setSetsArray] = useState([])
    const [sets, setSets] = useState(null)

    const userid = localStorage.getItem('loggedUserID')

    useEffect(() => {
        getRecommended()
    }, [])

    const openSet = async setID => {
        await myFetch('/api/addToLastOpened', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userid: userid, setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                navigate('/openSet/' + setID)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getRecommended = async () => {
        await myFetch('/api/getRecommended', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(res => {
                if (res.status == 0) {
                    setSetsArray(res.sets)
                    let temp = []

                    res.sets.forEach((element, i) => {
                        let photoPath = ''
                        if (element.category == 1) {
                            photoPath = require('../../assets/images/english.png')
                        } else if (element.category == 2) {
                            photoPath = require('../../assets/images/german.png')
                        } else {
                            photoPath = require('../../assets/images/fishki_nobg_sm.png')
                        }
                        temp.push(
                            <div
                                onClick={() => {
                                    openSet(element.id)
                                }}
                                className='setThumb'>
                                <div className='imgBG'></div>
                                <img src={photoPath} />
                                <Tooltip title={element.name}>
                                    <header>{element.name}</header>
                                </Tooltip>
                            </div>
                        )
                    })
                    temp.unshift(
                        <div className='setsHeader'>
                            <header>
                                <span>Polecane</span> <br></br> Przez <br></br> Twórców
                            </header>
                        </div>
                    )
                    setSets(temp)
                } else {
                    setAlertPlaceholder(
                        myAlert(
                            'Wystąpił błąd',
                            res.error,
                            'Zamknij',
                            () => {
                                setAlertPlaceholder(null)
                            },
                            'error'
                        )
                    )
                }
            })
            .catch(err => {
                console.log(err)
                setAlertPlaceholder(
                    myAlert(
                        'Wystąpił błąd',
                        'Błąd połączenia z serwerem',
                        'Zamknij',
                        () => {
                            setAlertPlaceholder(null)
                        },
                        'error'
                    )
                )
            })
    }

    return (
        <section className='newSetsSectionContainer'>
            <article>{sets}</article>
        </section>
    )
}

export default RecomendedSection
