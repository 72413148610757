import { Button } from '@mui/material'

const HowToUse = ({ setShowHelp }) => {
    return (
        <section className='helpCard'>
            <header>Jak korzystać z edytora?</header>
            <p>Edytor został napisany w taki sposób, aby pisanie fiszek było możliwie jak najbardziej efektywne czasowo.</p>
            <p>Będąc na pierwszej stronie fiszki wystarczy kliknąć tabulator, a fiszka sama się obróci</p>
            <p>Będąc na drugiej stronie kliknięcie przycisku tabulacji spowoduje najechanie na przycisk do dodawania fiszki do zestawu - wtedy wysatrczy kliknąć enter, aby dodać fiszkę</p>
            <p>Po dodaniu fiszki nastąpi automatyczny obrót na pierwszą stronę i wejście w panel wprowadzania </p>
            <p>Ponadto po każdym kliknięciu w znak specjalny z dolnego paska automatycznie wraca do pola wprowadzania - polecamy zostawić kursor na dolnym panelu i wykonywać tylko pojedyncze klinięcia</p>
            <p>Edytor posiada również funkcją copitlota, która pozwala na automatyczne tłumaczenie fiszki na wybrany język na drugiej stronie</p>

            <Button
                variant='contained'
                onClick={() => {
                    setShowHelp(false)
                }}>
                Zamknij
            </Button>
        </section>
    )
}

export default HowToUse
