import { useState } from 'react'
import Register from '../register/Register'
import Login from '../login/Login'

import './loginOrRegister.scss'
import { useTheme, Button } from '@mui/material'

import LogoNoBg from '../../assets/images/fishki_nobg_sm.png'
import Navbar from '../../utils/Navbar/Navbar'

//to bedzie glowna strona do logowania i rejestracji
//na panelu uzytkownik moze za pomoca przycisku zmieniac miedzy logowaniem a rejestracja

const LoginOrRegister = ({ starting }) => {
    const theme = useTheme()

    const [content, setContent] = useState(starting === 'login' ? true : false) //true jesli logowanie, false jesli rejestracja
    //trzeba dodac jakis header na gorze strony z nazwa Fishki czy cos
    return (
        <>
            <Navbar />
            <main id='loginOrReg'>
                <div className='container'>
                    <article className='card' style={{ backgroundColor: theme.palette.background.paper }}>
                        {content ? <Login /> : <Register />}
                    </article>

                    <Button variant='outlined' color='secondary' onClick={() => setContent(!content)}>
                        {content ? 'Nie masz konta?' : 'Masz już konto?'}
                    </Button>
                </div>
            </main>
        </>
    )
}

export default LoginOrRegister

{
    /* <aside>
                <h1 style={{ color: theme.palette.text.primary }}>FISHKI</h1>
                <img src={fishkiIcon} />
            </aside> */
}

{
    /* <section>
                <Button variant='contained' onClick={() => setContent(!content)}>
                    {content ? 'Nie masz konta?' : 'Masz juz konto?'}
                </Button>
                {content ? <Login /> : <Register />}
            </section> */
}
