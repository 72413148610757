import { Modal, Button } from '@mui/material'
import makeSetCopy from './makeSetCopy'

const NoPermissions = ({ navigate, setAlertPlaceholder, setID, userid }) => {
    return (
        <Modal className='modal' open>
            <div style={{ borderColor: 'rgb(200, 0, 0)' }} className='modalContent'>
                <h1 style={{ color: 'rgb(180, 0, 0)' }}>Wystąpił błąd</h1>
                <p>Nie masz uprawnień do edycji oryginalnego zestawu. Czy chcesz wykonać kopię?</p>
                <Button
                    onClick={() => {
                        makeSetCopy(setID, userid, setAlertPlaceholder, navigate)
                    }}
                    style={{ marginLeft: 10 }}
                    variant='contained'>
                    Wykonaj kopię
                </Button>
                <Button
                    onClick={() => {
                        setAlertPlaceholder(null)
                        navigate('/')
                    }}
                    variant='contained'>
                    Powrót
                </Button>
            </div>
        </Modal>
    )
}

export default NoPermissions
