import Logo from '../../assets/images/fishki_nobg_sm.png'
import './Navbar.scss'
import useUserData from '../../hooks/useUserData'

import { useTheme, Button, IconButton, Tooltip } from '@mui/material'
import { Menu, AccountCircle } from '@mui/icons-material'
import { useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
    const userid = localStorage.getItem('loggedUserID')
    const [menuClick, setMenuClick] = useState(false)
    const theme = useTheme()
    const { userData, error } = useUserData(userid)
    const navigate = useNavigate()

    const link = window.location.hostname === 'localhost' ? 'https://localhost:3001/' : 'https://fishki.rurbanski.pl/'

    const showMenu = () => {
        setMenuClick(prev => !prev)
    }

    const links = [
        { name: 'Dodaj nowy zestaw', href: '/newset', label: '' },
        { name: 'Pobierz aplikację', href: '/download', label: '' },
        { name: 'Informacje o becie', href: '/roadmap', label: '' },
        { name: <AccountCircle />, href: `/profile/${userid}`, label: userData?.nick, account: true },
    ]

    const logout = () => {
        localStorage.removeItem('loggedUserID')
        navigate('/')
    }

    const toMain = () => {
        navigate('/')
    }

    return (
        <header id='header' style={{ color: theme.palette.font.color, background: `linear-gradient(rgba(4, 44, 61, 1), transparent)` }}>
            <div className='container-full'>
                <a onClick={toMain} className='navbar-brand'>
                    <img src={Logo} alt='Logo fishek' />
                    <p>Fishki</p>
                </a>

                <span className='toggler' onClick={showMenu}>
                    <Menu />
                </span>

                <nav className={`navbar ${menuClick ? 'showed' : ''}`}>
                    <ul className='links'>
                        {userData == null ? (
                            <a className='icon-link' href='/login'>
                                <Button variant='contained'>Zaloguj</Button>
                            </a>
                        ) : (
                            <>
                                {links.map((val, index) => (
                                    <li key={index}>
                                        <a className={typeof val.name === 'string' ? 'underline' : 'icon-link'} href={val.href}>
                                            {val.button ? (
                                                <Button className='link-button' variant='contained'>
                                                    {val.name} &nbsp; {val.label && <span>{val.label}</span>}
                                                </Button>
                                            ) : val.account ? (
                                                <img className='link-account' src={`${link + userData.photoPath}`} />
                                            ) : (
                                                `${val.name} ${val.label}`
                                            )}
                                        </a>
                                    </li>
                                ))}
                                <Tooltip title='Wyloguj się'>
                                    {' '}
                                    <IconButton onClick={logout} color='secondary' size='large'>
                                        <LogoutIcon fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Navbar
