import Navbar from '../../utils/Navbar/Navbar'
import './setEditor.scss'
import myAlert from '../../utils/AlertProvider'
import myFetch from '../../utils/myFetch'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TextField, Tooltip, Select, MenuItem, FormControlLabel, Switch, Button, useTheme, IconButton, Backdrop } from '@mui/material'

import SaveIcon from '@mui/icons-material/Save'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import english from '../../assets/images/english.webp'
import german from '../../assets/images/german.webp'
import fishki_nobg from '../../assets/images/fishki_nobg.webp'
import NoPermissions from './NoPermissions'
import JSONReader from './FileReader'
import HowToUse from './HowToUse'
import HowToJSON from './HowToJSON'

const SetEditor = () => {
    const userid = localStorage.getItem('loggedUserID')
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const [setName, setSetName] = useState('')
    const [arrayFiszki, setArrayFiszki] = useState([])
    const [kategoria, setKategoria] = useState(1)
    const [copilot, setCopilot] = useState(false)
    const [setIcon, setSetIcon] = useState()
    const titleRef = useRef()
    const { setID } = useParams()
    const [fiszkaStatus, setFiszkaStatus] = useState(false)
    const [animationValue, setAnimationValue] = useState('none')
    const animationStarter = 'obrotFiszki 0.5s linear'
    const theme = useTheme()
    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')
    const [isEditing, setIsEditing] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [showJSONHelp, setShowJSONHelp] = useState(false)
    const saveButtonRef = useRef()
    const tabRef = useRef()
    const ref = useRef()
    const navigate = useNavigate()

    useEffect(() => {
        if (userid == null) {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Musisz być zalogowany, aby móc dodawać własne zestawy',
                    'Powrót',
                    () => {
                        setAlertPlaceholder(null)
                        navigate('/')
                    },
                    'error'
                )
            )
        } else if (isMobileDevice()) {
            setAlertPlaceholder(
                myAlert(
                    'Przepraszamy',
                    'Edytor nie jest obecnie dostępny na urządzenia mobilne',
                    'Powrót',
                    () => {
                        setAlertPlaceholder(null)
                        navigate('/')
                    },
                    'warning'
                )
            )
        } else {
            if (setID) {
                getSetData()
            } else {
                setSetName('NOWY ZESTAW FISZEK')
                setSetIcon(english)
            }
        }
    }, [])

    const saveChanges = async () => {
        if (arrayFiszki.length < 1) {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Zestaw fiszek jest pusty',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
        } else {
            if (setID) {
                //aktualizujemy istniejacy zestaw
                await myFetch('/api/updateSet', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ setID: setID, userid: userid, arrayFiszki: arrayFiszki, category: kategoria, setName: setName }),
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 0) {
                            setAlertPlaceholder(
                                myAlert(
                                    'Zapisano',
                                    'Zestaw został zaktualizowany',
                                    'Zamknij',
                                    () => {
                                        setAlertPlaceholder(null)
                                    },
                                    'success'
                                )
                            )
                        } else {
                            setAlertPlaceholder(
                                myAlert(
                                    'Wystąpił błąd',
                                    res.error,
                                    'Zamknij',
                                    () => {
                                        setAlertPlaceholder(null)
                                    },
                                    'error'
                                )
                            )
                        }
                    })
            } else {
                //zapisujemy nowy zestaw
                await myFetch('/api/saveSet', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        userid: userid,
                        arrayFiszki: arrayFiszki,
                        category: kategoria,
                        setName: setName,
                    }),
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 0) {
                            setAlertPlaceholder(
                                myAlert(
                                    'Zapisano',
                                    'Zestaw fiszek został zapisany',
                                    'Zamknij',
                                    () => {
                                        setAlertPlaceholder(null)
                                    },
                                    'success'
                                )
                            )
                        } else {
                            setAlertPlaceholder(
                                myAlert(
                                    'Wystąpił błąd',
                                    res.error,
                                    'Zamknij',
                                    () => {
                                        setAlertPlaceholder(null)
                                    },
                                    'error'
                                )
                            )
                        }
                    })
            }
        }
    }

    const getSetData = async () => {
        await myFetch('/api/getSetData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.author.id != userid) {
                    setAlertPlaceholder(<NoPermissions userid={userid} setID={setID} navigate={navigate} setAlertPlaceholder={setAlertPlaceholder} />)
                }
                setSetName(res.name)
                setArrayFiszki(res.fiszki)
                if (res.category == 1) {
                    setSetIcon(english)
                    setKategoria(1)
                } else if (res.category == 2) {
                    setSetIcon(german)
                    setKategoria(2)
                } else {
                    setSetIcon(fishki_nobg)
                    setKategoria(3)
                }
            })
    }

    const zmienKategorie = e => {
        setKategoria(e.target.value)
        if (e.target.value == 3) {
            setCopilot(false)
        }
        if (e.target.value == 1) {
            setSetIcon(english)
            console.log('angielski')
        } else if (e.target.value == 2) {
            setSetIcon(german)
            console.log('niemiecki')
        } else {
            setSetIcon(fishki_nobg)
            console.log('inne')
        }
    }

    const addToList = () => {
        if (text1 == '' && text2 == '') {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Fiszka jest pusta z obydwu stron',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
            return
        }
        let temp = []
        if (isEditing === false) {
            temp = [...arrayFiszki, { side1: text1, side2: text2 }]
        } else {
            temp = arrayFiszki.map((e, i) => (i != isEditing ? e : { side1: text1, side2: text2 }))
            console.log(temp)
            setIsEditing(false)
        }

        setArrayFiszki(temp)
        setText1('')
        setText2('')
        if (fiszkaStatus) {
            changeFiszkaStatus()
            setTimeout(() => {
                ref.current.focus()
            }, 500)
        } else {
            ref.current.focus()
        }
    }

    const checkTabPress2 = async e => {
        if (e.keyCode == 9) {
            changeFiszkaStatus()
            setTimeout(() => {
                saveButtonRef.current.focus()
            }, 500)
        }
    }

    const checkTabPress = async e => {
        if (e.keyCode == 9) {
            changeFiszkaStatus()
            setTimeout(() => {
                tabRef.current.focus()
            }, 500)

            console.log(copilot)

            if (copilot) {
                await myFetch('/api/translate', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        category: kategoria,
                        text: text1,
                    }),
                })
                    .then(res => res.json())
                    .then(res => {
                        console.log(res)
                        if (res.status == 0) {
                            setText2(res.translate)
                            //tabRef.current.select()
                        } else {
                            setAlertPlaceholder(
                                myAlert(
                                    'Wystąpił błąd',
                                    res.error,
                                    'Zamknij',
                                    () => {
                                        setAlertPlaceholder(null)
                                    },
                                    'error'
                                )
                            )
                        }
                    })
                    .catch(err => {
                        setAlertPlaceholder(
                            myAlert(
                                'Wystąpił błąd',
                                err,
                                'Zamknij',
                                () => {
                                    setAlertPlaceholder(null)
                                },
                                'error'
                            )
                        )
                    })
            }
        }
    }

    const changeFiszkaStatus = async () => {
        setAnimationValue(animationStarter)
        setTimeout(() => {
            setFiszkaStatus(!fiszkaStatus)
        }, 250)
        setTimeout(() => {
            setAnimationValue('none')
        }, 500)
    }

    const edit = i => {
        setText1(arrayFiszki[i].side1)
        setText2(arrayFiszki[i].side2)
        setIsEditing(i)
    }

    const addLeter = letter => {
        if (fiszkaStatus) {
            setText2(text2 + letter)
            tabRef.current.focus()
        } else {
            setText1(text1 + letter)
            ref.current.focus()
        }
    }

    const deleteFromList = i => {
        let temp = arrayFiszki.filter((e, index) => index !== i)
        setArrayFiszki(temp)
    }

    const isMobileDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera
        const isUserAgentMobile = /android|iPad|iPhone|iPod/i.test(userAgent.toLowerCase())
        const isViewportMobile = window.innerWidth < 500
        return isUserAgentMobile || isViewportMobile
    }

    return (
        <>
            <Navbar />
            <main className='setEditorMain'>
                {setIcon ? <img className='background-image' src={setIcon} /> : null}

                <header>
                    <h1>Edytor zestawów</h1>
                </header>
                <article>
                    <aside className='setOptions'>
                        <h4>Nazwa zestawu</h4>
                        <Tooltip title={'Nazwa zestawu'}>
                            <TextField fullWidth onClick={() => titleRef.current.select()} inputRef={titleRef} inputProps={{ min: 0, style: { textAlign: 'center' } }} value={setName} onChange={e => setSetName(e.target.value)} variant='standard' />
                        </Tooltip>
                        <h4>Opcje edytora</h4>
                        <p>Język</p>
                        <section>
                            <Select value={kategoria} onChange={zmienKategorie}>
                                <MenuItem value={1}>Angielski</MenuItem>
                                <MenuItem value={2}>Niemiecki</MenuItem>
                                <MenuItem value={3}>Inne</MenuItem>
                            </Select>

                            <FormControlLabel
                                style={{ marginLeft: 15 }}
                                control={
                                    <Tooltip title='Copilot zaproponuje tłumaczenia na drugiej stronie fiszki'>
                                        <Switch disabled={kategoria == 3} checked={copilot} onChange={e => setCopilot(e.target.checked)} color='secondary' />
                                    </Tooltip>
                                }
                                label='Copilot'
                            />
                        </section>
                        <p>Lista fiszek</p>
                        <section>
                            <Button onClick={saveChanges} startIcon={<SaveIcon />} variant='outlined' color='success'>
                                Zapisz zestaw
                            </Button>
                            <Button ref={saveButtonRef} onClick={addToList} startIcon={<AddBoxOutlinedIcon />} variant='outlined' color='primary'>
                                Dodaj fiszkę
                            </Button>
                        </section>
                        <p>Inne</p>
                        <section>
                            <JSONReader setAlertPlaceholder={setAlertPlaceholder} setArrayFiszki={setArrayFiszki} />
                        </section>
                        <p>Pomoc</p>
                        <section>
                            <Button
                                onClick={() => {
                                    setShowHelp(true)
                                }}
                                color='warning'
                                variant='outlined'>
                                Jak korzystać z edytora?
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowJSONHelp(true)
                                }}
                                color='warning'
                                variant='outlined'>
                                Jak wczytywać pliki JSON?
                            </Button>
                        </section>
                    </aside>
                    <main>
                        <section
                            className='fiszka'
                            style={{
                                backgroundColor: fiszkaStatus ? theme.palette.secondary.main : theme.palette.primary.main,
                                animation: animationValue,
                            }}>
                            <header>
                                {fiszkaStatus ? '2 strona' : '1 strona'}
                                <Tooltip title='Obróć fiszkę (tab)'>
                                    <IconButton onClick={changeFiszkaStatus}>{fiszkaStatus ? <RotateLeftIcon /> : <RotateRightIcon />}</IconButton>
                                </Tooltip>
                            </header>
                            <div>
                                {fiszkaStatus ? (
                                    <TextField placeholder='Wpisz tutaj treść' multiline maxRows={4} className='inputCard' variant='outlined' color='primary' onChange={e => setText2(e.target.value)} inputRef={tabRef} value={text2} onKeyDown={checkTabPress2} />
                                ) : (
                                    <TextField placeholder='Wpisz tutaj treść' multiline maxRows={4} className='inputCard' color='secondary' variant='outlined' onChange={e => setText1(e.target.value)} value={text1} inputRef={ref} onKeyDown={checkTabPress} />
                                )}
                            </div>
                        </section>
                        <section className='lettersContainer'>
                            <Button onClick={() => addLeter('ä')} variant='contained' style={{ textTransform: 'none' }}>
                                ä
                            </Button>
                            <Button onClick={() => addLeter('ö')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                ö
                            </Button>
                            <Button onClick={() => addLeter('ü')} variant='contained' style={{ textTransform: 'none' }}>
                                ü
                            </Button>
                            <Button onClick={() => addLeter('Ä')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                Ä
                            </Button>
                            <Button onClick={() => addLeter('Ö')} variant='contained' style={{ textTransform: 'none' }}>
                                Ö
                            </Button>
                            <Button onClick={() => addLeter('Ü')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                Ü
                            </Button>
                            <Button onClick={() => addLeter('ß')} variant='contained' style={{ textTransform: 'none' }}>
                                ß
                            </Button>
                        </section>
                    </main>
                    <aside className='setList'>
                        <section>
                            <header>Lista fiszek</header>
                            <ul>
                                {arrayFiszki.map((element, i) => (
                                    <li key={i}>
                                        <p>{element.side1}</p>
                                        <Tooltip title='Edytuj tą fiszkę'>
                                            <IconButton
                                                onClick={() => {
                                                    edit(i)
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Usuń z zestawu'>
                                            <IconButton
                                                onClick={() => {
                                                    deleteFromList(i)
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <p>{element.side2}</p>
                                    </li>
                                ))}
                            </ul>
                        </section>
                    </aside>
                </article>
                <Backdrop style={{ zIndex: 100 }} open={showHelp}>
                    <HowToUse setShowHelp={setShowHelp} />
                </Backdrop>
                <Backdrop style={{ zIndex: 100 }} open={showJSONHelp}>
                    <HowToJSON setShowHelp={setShowJSONHelp} />
                </Backdrop>
            </main>
            {alertPlaceholder}
        </>
    )
}

export default SetEditor
