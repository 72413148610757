import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import myFetch from '../../utils/myFetch'
import Navbar from '../../utils/Navbar/Navbar'
import { Button, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Tooltip, useTheme, LinearProgress } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import './match.scss'

const Match = () => {
    const textRef = useRef()
    const userID = localStorage.getItem('loggedUserID')
    const navigate = useNavigate()
    const theme = useTheme()
    const { matchID, setID } = useParams()
    const [setData, setSetData] = useState(null)
    const [arrayFiszki, setArrayFiszki] = useState([{ side1: '', side2: '' }])
    const [defaultArray, setDefaultArray] = useState([])
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const [actualPosition, setActualPosition] = useState(0)
    const [matchData, setMatchData] = useState(null)
    const [opponentData, setOpponentData] = useState(null)
    const [opponentName, setOpponentName] = useState('')
    const [opponentIcon, setOpponentIcon] = useState('')
    const [text, setText] = useState('')
    const [animationValue, setAnimationValue] = useState('none')
    const [doPoprawy, setDoPoprawy] = useState([])
    const [inputLabel, setInputLabel] = useState('')
    const [inputError, setInputError] = useState(false)
    const [finishedSection, setFinishedSection] = useState(null)
    const nextPisanieStarter = 'nastepnePisanie 0.5s linear'
    const link = window.location.hostname === 'localhost' ? 'https://localhost:3001/' : 'https:/fishki.rurbanski.pl/'

    useEffect(() => {
        let x = setInterval(() => {}, 1000)
        for (let i = 0; i <= x; i++) {
            clearInterval(i)
        }
        getSetData()
    }, [])

    const goBack = () => {
        navigate(-1)
    }

    const getSetData = async () => {
        await myFetch('/api/getSetData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(async res => {
                setSetData(res)
                setArrayFiszki(res.fiszki)
                await startConnection(res.fiszki)
            })
    }

    const getUserData = async id => {
        await myFetch('/api/getBasicUserData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userid: id }),
        })
            .then(res => res.json())
            .then(res => {
                setOpponentName(res.nick)
                setOpponentIcon(link + res.photoPath)
            })
    }

    const startConnection = async fiszki => {
        let arrayFiszki = fiszki
        await myFetch('/api/startConnection', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ matchID: matchID, userID: userID, actualPosition: 0, max: arrayFiszki.length }),
        })
            .then(res => res.json())
            .then(async res => {
                setMatchData(res.match)
                if (userID == res.match.guestID) {
                    setOpponentData({ position: res.match.hostPosition, max: res.match.hostMax })
                    getUserData(res.match.hostID)
                } else {
                    setOpponentData({ position: res.match.guestPosition, max: res.match.guestMax })
                    await getUserData(res.match.guestID)
                }
                const updater = setInterval(async () => {
                    await myFetch('/api/updateMatchData', {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ matchID: matchID, userID: userID }),
                    })
                        .then(res => res.json())
                        .then(res => {
                            setMatchData(res.match)
                            if (userID == res.match.guestID) {
                                setOpponentData({ position: res.match.hostPosition, max: res.match.hostMax })
                            } else {
                                setOpponentData({ position: res.match.guestPosition, max: res.match.guestMax })
                            }
                        })
                }, 500)
            })
    }

    const addLeter = leter => {
        setText(text + leter)
        textRef.current.focus()
    }

    const nastepnaFiszka = () => {
        if (actualPosition < arrayFiszki.length - 1) {
            setAnimationValue(nextPisanieStarter)
            setTimeout(() => {
                setActualPosition(actualPosition + 1)
                updateMatchPosition(actualPosition + 1)
                setText('')
                setInputError(false)
                setInputLabel('')
            }, 250)
            setTimeout(() => {
                setAnimationValue('none')
            }, 500)
        } else {
            if (doPoprawy.length > 0) {
                updateMatchPosition(0, doPoprawy.length)
                setActualPosition(0)
                setArrayFiszki(doPoprawy)
                setDoPoprawy([])
                setText('')
                setInputError(false)
                setInputLabel('')
            } else {
                alert('wygrales')
                setFinishedSection(
                    <section className='finishedSection'>
                        <header style={{ color: theme.palette.secondary.main }}>KONIEC</header>
                        <div>
                            <img src={require('../../assets/images/fajen1.png')} />
                            <div style={{ color: theme.palette.primary.main }}>UDALO CI SEI</div>
                            <img src={require('../../assets/images/fajen2.png')} />
                        </div>
                        <div>
                            <Button onClick={goBack} variant='contained'>
                                Powrót
                            </Button>
                            <Button onClick={restart} variant='contained'>
                                Uruchom ponownie
                            </Button>
                        </div>
                    </section>
                )
            }
        }
    }

    const updateMatchPosition = (position, max = arrayFiszki.length) => {
        myFetch('/api/updateMatchPosition', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ matchID: matchID, userID: userID, actualPosition: position + 1, max: max }),
        })
            .then(res => res.json())
            .then(res => {})
    }

    const restart = () => {
        setActualPosition(0)
        setArrayFiszki(defaultArray)
        setDoPoprawy([])
        setText('')
        setInputError(false)
        setInputLabel('')
        setFinishedSection(null)
    }

    const checkInput = () => {
        if (text == arrayFiszki[actualPosition].side2) {
            nastepnaFiszka()
        } else {
            setInputError(true)
            if (doPoprawy.indexOf(arrayFiszki[actualPosition]) == -1) {
                setDoPoprawy([...doPoprawy, arrayFiszki[actualPosition]])
                setInputLabel(arrayFiszki[actualPosition].side2)
            }
        }
    }

    const updateInput = e => {
        let newText = e.target.value
        setText(newText)
        if (newText == arrayFiszki[actualPosition].side2) {
            nastepnaFiszka()
        }
    }

    return (
        <>
            <Navbar />
            <div className='mainMatch'>
                <header>{setData ? setData.name : 'Wczytywanie...'} </header>
                <div>
                    <article style={{ animation: animationValue }}>
                        <header>{arrayFiszki[actualPosition].side1}</header>
                        <div>
                            <div className='horizontalContainer'>
                                <TextField error={inputError} inputRef={textRef} value={text} onChange={updateInput} variant='standard' />
                                <IconButton onClick={checkInput} color='primary' size='large'>
                                    <CheckIcon fontSize='inherit' />
                                </IconButton>
                            </div>
                            <span>{inputLabel}</span>
                            <section className='lettersContainer'>
                                <Button onClick={() => addLeter('ä')} variant='contained' style={{ textTransform: 'none' }}>
                                    ä
                                </Button>
                                <Button onClick={() => addLeter('ö')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                    ö
                                </Button>
                                <Button onClick={() => addLeter('ü')} variant='contained' style={{ textTransform: 'none' }}>
                                    ü
                                </Button>
                                <Button onClick={() => addLeter('Ä')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                    Ä
                                </Button>
                                <Button onClick={() => addLeter('Ö')} variant='contained' style={{ textTransform: 'none' }}>
                                    Ö
                                </Button>
                                <Button onClick={() => addLeter('Ü')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                    Ü
                                </Button>
                                <Button onClick={() => addLeter('ß')} variant='contained' style={{ textTransform: 'none' }}>
                                    ß
                                </Button>
                            </section>
                        </div>
                        <footer>{actualPosition + 1 + ' / ' + arrayFiszki.length}</footer>
                    </article>
                    <aside>
                        {opponentData ? (
                            <>
                                <p>{opponentName}</p>
                                <img src={opponentIcon} />
                                <p>Postęp przeciwnika</p>
                                <LinearProgress style={{ width: '50%' }} variant='determinate' value={(opponentData.position / opponentData.max) * 100} />
                                <p>
                                    {opponentData.position} / {opponentData.max}
                                </p>
                            </>
                        ) : (
                            'Wczytywanie...'
                        )}
                    </aside>
                </div>
            </div>
            {alertPlaceholder}
            {finishedSection}
        </>
    )
}

export default Match
