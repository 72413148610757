import { Tooltip, useTheme } from '@mui/material'
import './itIsBeta.scss'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'
import { useNavigate } from 'react-router-dom'

const ItIsBeta = ({ setAlertPlaceholder }) => {
    const theme = useTheme()

    const navigate = useNavigate()

    const [headerText, setHeaderText] = useState('')

    const headerTexts = ['schön dich zu sehen!', 'glad to see you!', 'miło cię widzeć!', 'content de te voir!', '¡me alegro de verte!', '만나서 반가워요']

    useEffect(() => {
        const random = Math.floor(Math.random() * headerTexts.length)
        setHeaderText(headerTexts[random])
    }, [])

    return (
        <section className='betaContainer'>
            <header>{headerText.toUpperCase()}</header>
            <p>Witaj z powrotem!</p>
            <p>Pamiętaj, że strona jest wciąż w becie! </p>
        </section>
    )
}

export default ItIsBeta
